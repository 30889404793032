module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.dentalsurgicenteroflouisville.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dental SurgiCenter of Louisville","lang":"en","display":"standalone","short_name":"Dental SurgiCenter of Louisville","description":"Dental SurgiCenter of Louisville provides outpatient care to patients of all ages in need of any surgical procedure. Our surgeons perform same-day surgery  in Louisville, KY, without requiring patients to visit a hospital, and providers can use this facility to perform surgery on their patients.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/favicon-512x512.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"0e8341ac70469f9896befd5fe6185377"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TD22HN7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
